<template>
  <div class="home">
    <custom-section class="section pa-0 pa-md-5">
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
      <!-- <div class="d-md-none d-flex flex-grow-1 align-center mb-3">
        <h4 class="tab-switch">{{ $t("role_as") }}:</h4>
        <tab />
      </div> -->
      <TabContent
        :listTab="listMenu"
        :listContent="listContent"
        class="mb-6 corp-m"
        :activeTab="activeTab"
        @changeTab="id => (activeTab = id)"
      >
        <template v-slot:tabs_content="content">
          <v-row v-if="activeTab == '1' || activeTab == '2'" dense>
            <v-col cols="6" class="d-none d-lg-block">
              <div v-for="(item, index) of content.data" :key="index">
                <v-row
                  no-gutters
                  class="list-data"
                  v-if="
                    item.valueTitle == 'EMAIL' ||
                    item.valueTitle == 'MOBILE PHONE' ||
                    item.valueTitle == 'HOME PHONE' ||
                    item.valueTitle == 'ID CARD'
                  "
                >
                  <v-col class="text-title">
                    {{ item.valueTitleLang }}
                  </v-col>
                  <v-col class="text-value">
                    {{ item.valueLeft }}
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="list-data"
                  v-if="
                    item.valueTitle !== 'EMAIL' &&
                    item.valueTitle !== 'MOBILE PHONE' &&
                    item.valueTitle !== 'HOME PHONE' &&
                    item.valueTitle !== 'ID CARD'
                  "
                >
                  <v-col class="text-new-title">
                    {{ $fnc.replaceSpecialChars(item.valueLeft) }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="6" class="d-none d-lg-block">
              <div v-for="(item, index) of content.dataRight" :key="index">
                <v-row no-gutters class="list-data">
                  <v-col class="text-title">
                    {{ item.title }}
                  </v-col>
                  <v-col class="text-value">
                    {{ item.value }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="activeTab == '1' || activeTab == '2'" dense>
            <v-col class="d-block d-lg-none" cols="12">
              <div v-for="item of content.dataMobile" :key="item.id">
                <v-row no-gutters class="list-data">
                  <v-col class="text-new-title">
                    {{ $fnc.replaceSpecialChars(item.valueLeft) }}
                  </v-col>
                </v-row>
              </div>
              <div
                v-for="(item, index) of content.dataMobileRight"
                :key="index"
              >
                <v-row no-gutters class="list-data">
                  <v-col class="text-title">
                    {{ item.title }}
                  </v-col>
                  <v-col class="text-value">
                    {{ item.value }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <!-- v-if="activeTab == '3'" -->
          <card v-if="content.id == '3'">
            <template v-slot:body>
              <v-col cols="12 pa-0">
                <BankInfo />
              </v-col>
            </template>
          </card>
          <!-- <card v-if="activeTab == '4'">
            <template v-slot:body>
              <v-col cols="12 pa-0">
                <OtherInfo />
              </v-col>
            </template>
          </card> -->
          <!-- <card v-if="activeTab == '4'">
            <template v-slot:body>
              <v-col cols="12 pa-2">
                <DepositContract />
              </v-col>
            </template>
          </card> -->
        </template>
      </TabContent>

      <v-row v-if="activeTab == '2'">
        <v-col cols="12">
          <card v-if="activeTab == '2'">
            <template v-slot:body>
              <v-col cols="12" class="pa-0">
                <ContactInfo />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row>
      <v-row v-if="activeTab == '1'">
        <v-col cols="12" lg="7">
          <v-row>
            <v-col cols="12">
              <card rounded shadow>
                <template v-slot:header>
                  <div class="card__header mb-2">
                    <div class="d-flex justify-space-between align-center">
                      <heading-card
                        :title="
                          $t('page.profile.number_of_patient_code_booking')
                        "
                      />
                      <select-show
                        :items="DateFilterItems"
                        :value="dateFilterDefaultValue"
                        @changeDate="nilai => updateAllReport(nilai)"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:body>
                  <v-expansion-panels
                    v-for="(data, index) of getBookingCode"
                    :key="index"
                    multiple
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header hide-actions>
                        <v-row class="d-none d-lg-flex d-md-flex">
                          <v-col cols="12" class="card-collapse">
                            <div class="card-booking">
                              <v-row no-gutters>
                                <v-col
                                  cols="12"
                                  md="5"
                                  class="d-flex justify-start align-center"
                                >
                                  <p class="booking-title">
                                    {{ data.group_code }}
                                  </p>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="7"
                                  class="booking-city d-flex justify-start align-center pt-0"
                                >
                                  <icons
                                    icon="maps"
                                    class="menu__icon"
                                    color="#00a4b3"
                                  />
                                  <span class="booking-detail">
                                    {{ data.plant_name }}
                                  </span>
                                </v-col>
                              </v-row>
                              <v-row no-gutters style="margin-top: 7px">
                                <v-col
                                  cols="12"
                                  md="5"
                                  class="d-flex justify-start align-center"
                                >
                                  <span class="product-name">
                                    {{ data.ordered_item }}</span
                                  >
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="7"
                                  class="booking-city d-flex justify-start align-center pt-0"
                                >
                                  <icons
                                    icon="date"
                                    class="menu__icon"
                                    color="#00a4b3"
                                  />
                                  <span class="booking-detail">
                                    {{ formatDate(data.date) }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row
                          class="d-block d-lg-none d-md-none"
                          style="margin-top: 6px"
                        >
                          <v-col cols="12" class="card-collapse-mobile">
                            <div class="card-booking">
                              <v-row no-gutters>
                                <v-col
                                  cols="7"
                                  class="d-flex justify-start align-center"
                                >
                                  <p class="booking-title">
                                    {{ data.group_code }}
                                  </p>
                                </v-col>
                                <v-col
                                  cols="5"
                                  class="booking-city d-flex justify-end align-center pt-0"
                                >
                                  <icons
                                    icon="date"
                                    class="menu__icon"
                                    color="#00a4b3"
                                  />
                                  <span class="booking-detail">
                                    {{ formatDate(data.date) }}
                                  </span>
                                </v-col>
                              </v-row>
                              <v-row no-gutters>
                                <v-col
                                  cols="12"
                                  class="booking-city d-flex justify-start align-center pt-2"
                                >
                                  <icons
                                    icon="maps"
                                    class="menu__icon"
                                    color="#00a4b3"
                                  />
                                  <span class="booking-detail">
                                    {{ data.plant_name }}
                                  </span>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="d-flex justify-start align-center pt-2"
                                >
                                  <span class="product-name">
                                    {{ data.ordered_item }}</span
                                  >
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          @pagination="setPageMyDepoHistory"
                          :headers="patientHeaders"
                          :items="data.data"
                          item-key="id"
                          class="elevation-1 table-stick-homedoctor super-dense"
                          :class="{
                            'table-stick-other': myDepoHistoryListCount > 15,
                          }"
                          :mobile-breakpoint="0"
                          :hide-default-footer="true"
                        >
                          <template v-slot:item="{ item }">
                            <tr>
                              <td class="tb-wide2">
                                <template
                                  v-if="item.status_name == 'COMPLETED'"
                                >
                                  <a
                                    v-if="item.type == 'LAB'"
                                    href="javascript:void(0);"
                                    @click="openDialogOrder(item)"
                                    >{{ item.order_number }}</a
                                  >
                                  <a
                                    v-else
                                    href="javascript:void(0);"
                                    @click="
                                      downloadLab(
                                        item.order_number,
                                        'simple',
                                        false
                                      )
                                    "
                                    >{{ item.order_number }}</a
                                  >
                                </template>
                                <template v-else>
                                  {{ item.order_number }}
                                </template>
                              </td>
                              <td class="tb-wide">
                                {{ item.patient_id_number }}
                              </td>
                              <td class="tb-wide">{{ item.patient }}</td>
                              <td class="tb-wide">
                                {{ item.status_name }}
                              </td>
                              <td align="right" valign="center">
                                {{ formater(item.amount) }}
                              </td>
                            </tr>
                          </template>
                          <!-- <template v-slot:[`body.append`]>
                            <tr class="sticky-table-footer">
                              <td />
                              <td />
                              <td />
                              <td v-text="'Total'" />
                              <td
                                align="right"
                                v-text="formater(sumReferral(data.data))"
                              />
                            </tr>
                          </template> -->
                          <template v-slot:[`footer`]>
                            <v-divider></v-divider>
                            <div class="d-flex align-end justify-end pa-3">
                              <p
                                class="mb-0 mr-3"
                                style="font-size: 12px; font-weight: 600"
                              >
                                Total :
                              </p>
                              <p
                                class="mb-0"
                                style="font-size: 12px; font-weight: 600"
                              >
                                {{ formater(sumReferral(data.data)) }}
                              </p>
                            </div>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </card>
            </v-col>
            <!-- <v-col cols="12">
            <card rounded shadow>
              <template v-slot:header>
                <div class="card__header">
                  <div class="d-flex justify-space-between align-center">
                    <heading-card
                      title="Total Daily Order by Booking Code BL22041808"
                    />
                  </div>
                  <SubHeadingCard :title="monthNow" />
                </div>
              </template>
              <template v-slot:body>
                <v-data-table
                  :headers="orderMonthHeader"
                  :items="orderMonthData"
                  item-key="id"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.date }}</td>
                      <td align="right">{{ item.patient }}</td>
                      <td align="right">
                        {{ formater(item.cost) }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:body.append>
                    <tr class="sticky-table-footer">
                      <td v-text="'Total'" />
                      <td align="right" v-text="sumReferralMonth('patient')" />
                      <td
                        align="right"
                        v-text="
                          formater(sumReferralMonth('cost'))
                        "
                      />
                    </tr>
                  </template>
                </v-data-table>
              </template>
            </card>
          </v-col> -->
          </v-row>
        </v-col>

        <v-col cols="12" lg="5">
          <v-row>
            <v-col cols="12">
              <card rounded shadow>
                <template v-slot:header>
                  <div class="card__header">
                    <div class="d-flex justify-space-between align-center">
                      <heading-card
                        :title="$t('page.profile.patient_summary')"
                      />
                      <select-show
                        :items="DateFilterItems"
                        :value="dateFilterDefaultValue"
                        @changeDate="nilai => updateAllReport(nilai)"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:body>
                  <v-col cols="12">
                    <div class="card-chart">
                      <h5 class="mb-5">
                        <div
                          class="d-flex justify-start align-center chart-title"
                        >
                          <radio />
                          <p class="mb-0 ml-2">
                            {{ $t("page.profile.total_patient") }}
                          </p>
                        </div>
                      </h5>
                      <apex-summary-chart
                        :data="dataChart"
                        v-if="dataChart != null"
                        :activeRange="dateFilterDefaultValue"
                      />
                    </div>
                  </v-col>
                </template>
              </card>
            </v-col>

            <v-col cols="12">
              <card rounded shadow>
                <template v-slot:header>
                  <div class="card__header">
                    <div class="d-flex justify-space-between align-center">
                      <heading-card :title="$t('page.profile.order_summary')" />
                      <select-show
                        :items="DateFilterItems"
                        :value="dateFilterDefaultValue"
                        @changeDate="nilai => updateAllReport(nilai)"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:body>
                  <v-col cols="12">
                    <div class="card-chart">
                      <h5 class="mb-5">
                        <div
                          class="d-flex justify-start align-center chart-title"
                        >
                          <description />
                          <p class="mb-0 ml-2">
                            {{ $t("page.profile.order_summary") }}
                          </p>
                        </div>
                      </h5>
                      <apex-referral-chart
                        :data="dataReferralChart"
                        v-if="dataReferralChart != null"
                        :activeRange="dateFilterDefaultValue"
                      />
                    </div>
                  </v-col>
                </template>
              </card>
            </v-col>

            <!-- <v-col cols="12">
              <card rounded shadow>
                <template v-slot:header>
                  <div class="card__header">
                    <div class="d-flex justify-space-between align-center">
                      <heading-card
                        :title="$t('page.profile.account_balance')"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:body>
                  <v-col cols="12">
                    <div class="card-chart">
                      <v-data-table
                        :headers="headers"
                        :items="datas"
                        item-key="id"
                        :mobile-breakpoint="0"
                        class="table-account-balance-company"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td class="tb-wide">{{ item.date }}</td>
                            <td align="right" valign="center" class="tb-wide0">
                              {{ formater(item.debit) }}
                            </td>
                            <td align="right" valign="center" class="tb-wide0">
                              {{ formater(item.credit) }}
                            </td>
                            <td align="right" valign="center" class="tb-wide0">
                              {{ formater(item.balance) }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                  </v-col>
                </template>
              </card>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="activeTab == '3'">
        <v-col cols="12">
          <card rounded shadow>
            <template v-slot:body>
              <v-col cols="12">
                <TaxInfo :dataTaxInfo="dataTaxInfo" v-if="activeTab == '3'" />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row>

      <!-- <v-row v-if="activeTab == '3'">
        <v-col cols="12">
          <card rounded shadow>
            <template v-slot:header>
              <div class="card__header">
                <div class="d-flex justify-space-between align-center">
                  <heading-card
                    :title="$t('page.profile.account_balance_info')"
                  />
                </div>
              </div>
            </template>
            <template v-slot:body>
              <v-col cols="12">
                <AccountBalanceInfo v-if="activeTab == '3'" />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row> -->
      <!-- <v-row v-if="activeTab == '4'">
        <v-col cols="12">
          <card rounded shadow>
            
            <template v-slot:body>
              <v-col cols="12">
                <DepositHistory v-if="activeTab == '4'" />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row> -->
      <!-- <v-row v-if="activeTab == '4'">
        <v-col cols="12">
          <card rounded shadow>
            <template v-slot:body>
              <v-col cols="12" class="pa-5">
                <DepositHistory v-if="activeTab == '4'" />
              </v-col>
            </template>
          </card>
        </v-col>
        <v-col cols="12">
          <card rounded shadow>
            <template v-slot:body>
              <v-col cols="12" class="pa-5">
                <TransactionHistory v-if="activeTab == '4'" />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row> -->
    </custom-section>
    <DialogOrderDetail
      v-if="dialog_order"
      :item="dataDialogOrder"
      :tampilkan="dialog_order"
      @toggleDialog="is_show => toggleDialogOrder(is_show)"
    />
  </div>
</template>

<script>
import TabContent from "@/components/v2/tabs/index.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import Card from "@/components/v2/card/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import SelectShow from "@/components/v2/select/SelectShow.vue";
import moment from "moment";
import Radio from "@/components/v2/radio/index.vue";
// import Tab from "@/components/v2/switch_account/tab.vue";
import Description from "@/components/v2/radio/referral.vue";
import ContactInfo from "@/views/v2/Company/Tabs/ContactInfo.vue";
import BankInfo from "@/views/v2/Company/Tabs/BankInfo.vue";
import TaxInfo from "@/views/v2/Company/Tabs/TaxInfo.vue";
// import OtherInfo from "@/views/v2/Company/Tabs/OtherInfo.vue";
// import AccountBalanceInfo from "@/views/v2/Company/Tabs/AccountBalanceInfo.vue";
import icons from "@/components/elements/Icons.vue";
import CustomSection from "@/components/v2/section/index.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import ApexSummaryChart from "@/views/v2/Patient/Chart/ApexSummaryChart.vue";
import ApexReferralChart from "@/views/v2/Patient/Chart/ApexReferralChart.vue";
// import DepositContract from "@/views/v2/Company/Tabs/DepositContract.vue";
// import DepositHistory from "@/views/v2/Company/Tabs/DepositHistory.vue";
// import TransactionHistory from "@/views/v2/Company/Tabs/TransactionHistory.vue";
import DialogOrderDetail from "@/components/v2/dialog/dialogOrderDetail.vue";

export default {
  components: {
    TabContent,
    // Tab,
    Card,
    HeadingCard,
    SelectShow,
    Radio,
    Description,
    ContactInfo,
    BankInfo,
    TaxInfo,
    // OtherInfo,
    // AccountBalanceInfo,
    // SubHeadingCard,
    icons,
    CustomSection,
    NavbarOne,
    ApexSummaryChart,
    ApexReferralChart,
    // DepositContract,
    // DepositHistory,
    // TransactionHistory,
    DialogOrderDetail,
  },
  data: () => ({
    dialog_order: false,
    dataDialogOrder: {
      order_id: null,
      list: [
        {
          document_information: {
            number_of_report: null,
            medical_record_number: null,
            datetime_observation: null,
            gender: null,
            plant: null,
            primary_doctor: null,
            lab_chief: null,
          },
          customers_information: {
            patient_name: null,
            address: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
            dob: null,
            patient_age: null,
          },
          product: {
            product_id: null,
            product_code: null,
            product_name: null,
          },
        },
      ],
    },
    myDepoHistoryListCount: 0,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    getBookingCode: [],
    dateNow: moment().format("DD MMMM, dddd"),
    monthNow: moment().format("MMMM YYYY"),
    DateFilterItems: [],
    dateFilterDefaultValue: "this_month",
    thisWeek: [],
    activeDoughnut: 1,
    percentDoughnut: 0,
    colorPercent: "#2ED47A",
    listPatient: null,
    listMenu: null,
    listContent: null,
    dataTaxInfo: null,
    dataChart: [],
    dataReferralChart: [
      {
        ColorFlag: 0,
        MaximumValue: 5000000,
        MinimumValue: 0,
        Result: 1000000,
        TestDate: "2022-02-12",
        medical_record_number: "88000000319",
        name: "Abdil Nulyaqin",
      },
      {
        ColorFlag: 0,
        MaximumValue: 5000000,
        MinimumValue: 0,
        Result: 500000,
        TestDate: "2022-02-13",
        medical_record_number: "88000000319",
        name: "Abdil Nulyaqin",
      },
      {
        ColorFlag: 0,
        MaximumValue: 5000000,
        MinimumValue: 0,
        Result: 1500000,
        TestDate: "2022-02-14",
        medical_record_number: "88000000319",
        name: "Abdil Nulyaqin",
      },
      {
        ColorFlag: 0,
        MaximumValue: 5000000,
        MinimumValue: 0,
        Result: 2000000,
        TestDate: "2022-02-15",
        medical_record_number: "88000000319",
        name: "Abdil Nulyaqin",
      },
    ],
    headers: [],
    datasX: [
      {
        date: "10-JAN-2022",
        debit: 25000000,
        credit: 0,
        balance: 25000000,
      },
      {
        date: "10-FEB-2022",
        debit: 55000000,
        credit: 0,
        balance: 80000000,
      },
      {
        date: "15-MAR-2022",
        patient: 7,
        debit: 0,
        credit: 25000000,
        balance: 55000000,
      },
      {
        date: "26-MAR-2022",
        debit: 0,
        credit: 55000000,
        balance: 0,
      },
      {
        date: "10-MAR-2022",
        debit: 125000000,
        credit: 0,
        balance: 1250000000,
      },
    ],
    patientHeaders: [],
    patientDatas: [
      {
        order_no: "2022.04.14/4/0001",
        patient_id: 4045,
        patient_name: "John Doe",
        status: "Completed",
        cost: 50000,
      },
      {
        order_no: "2022.04.14/4/0002",
        patient_id: 4046,
        patient_name: "Jane Doe",
        status: "Completed",
        cost: 50000,
      },
      {
        order_no: "2022.04.14/4/0003",
        patient_id: 4047,
        patient_name: "Mary Anne",
        status: "Canceled",
        cost: 50000,
      },
      {
        order_no: "2022.04.14/4/0004",
        patient_id: 4048,
        patient_name: "Elias Immanuel",
        status: "Completed",
        cost: 50000,
      },
      {
        order_no: "2022.04.14/4/0005",
        patient_id: 4049,
        patient_name: "Mikhaila Immanuel",
        status: "Active",
        cost: 50000,
      },
      {
        order_no: "2022.04.14/4/0006",
        patient_id: 4050,
        patient_name: "Stefanus Archee",
        status: "Active",
        cost: 50000,
      },
      {
        order_no: "2022.04.14/4/0007",
        patient_id: 4051,
        patient_name: "Nikita Angelmay",
        status: "Canceled",
        cost: 50000,
      },
      {
        order_no: "2022.04.14/4/0008",
        patient_id: 4052,
        patient_name: "Eva Rianne",
        status: "Canceled",
        cost: 50000,
      },
    ],
    orderMonthHeader: [
      { text: "Date", value: "date", sortable: false, align: "left" },
      {
        text: "Number of Patients",
        value: "patient",
        sortable: false,
        align: "right",
      },
      { text: "Cost", value: "cost", sortable: false, align: "right" },
    ],
    orderMonthData: [
      {
        date: "1-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "2-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "3-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "4-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "5-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "6-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "7-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "8-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "9-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "10-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "11-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "12-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "13-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "14-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "15-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "16-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "17-APR-2022",
        patient: 2,
        cost: 100000,
      },
      {
        date: "18-APR-2022",
        patient: 2,
        cost: 100000,
      },
    ],
    activeTab: "",
    dialog: false,
    show: false,
    contactPrimary: [],
    datas: [],
  }),
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  created() {
    this.DateFilterItems = [
      { dateValue: "today", dateLabel: this.$t("today") },
      { dateValue: "this_month", dateLabel: this.$t("this_month") },
      { dateValue: "last_month", dateLabel: this.$t("last_month") },
      { dateValue: "last_3_month", dateLabel: this.$t("last_3_month") },
      { dateValue: "this_year", dateLabel: this.$t("this_year") },
      { dateValue: "life_time", dateLabel: this.$t("lifetime") },
    ];
    this.headers = [
      {
        text: this.$t("page.profile.date"),
        value: "date",
        sortable: false,
        align: "left",
        width: "120px",
      },
      {
        text: this.$t("page.profile.debit"),
        value: "debit",
        sortable: false,
        align: "right",
      },
      {
        text: this.$t("page.profile.credit"),
        value: "credit",
        sortable: false,
        align: "right",
      },
      {
        text: this.$t("page.profile.balance"),
        value: "balance",
        sortable: false,
        align: "right",
      },
    ];
    this.patientHeaders = [
      {
        text: this.$t("page.profile.order_no"),
        value: "order_no",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_id"),
        value: "patient_id",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.cost"),
        value: "cost",
        sortable: false,
        align: "center",
      },
    ];
    this.getDateThisWeek();
    if (this.dataProfile) {
      this.getProfile();
    }
    this.getPatient();
    this.getInitialSummaryChart();
    this.getInitialReferralChart();
  },
  methods: {
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    downloadLab(param, type, isProduct) {
      if (isProduct) {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-labreport?order_number=${param}&type=${type}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      } else {
        API.get(
          `${process.env.VUE_APP_API_TRUST}diagnosis/get-mcureport?order_number=${param}&type=${type}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          this.download = res;
          window.open(this.download.linkDownload, "_blank");
        });
      }
    },

    async openDialogOrder(item) {
      this.dataDialogOrder.order_id = item.order_id;
      this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailPopup();
      this.dialog_order = true;
    },
    async getOrderDetailPopup() {
      let order_id = this.dataDialogOrder.order_id;
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}lab-results/get-detail?order_id=` +
            order_id +
            ``,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogOrder.list = res.results;
      } catch (error) {
        console.log(error);
      }
    },
    setPageMyDepoHistory(data) {
      this.myDepoHistoryListCount = data.pageStop - data.pageStart;
    },
    formater(value) {
      let angka = parseInt(value) || 0;
      let nilai = angka.toLocaleString("en-US", { minimumFractionDigits: 2 });
      return nilai;
    },
    async getPatient() {
      let show_by = this.dateFilterDefaultValue;

      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-order?show_by=` +
            show_by,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        this.getBookingCode = res.results;
      } catch (error) {
        console.log(error);
      }
    },
    updateAllReport(e) {
      this.dateFilterDefaultValue = e;
      this.getInitialReferralChart();
      this.getInitialSummaryChart();
      this.getPatient();
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    async getInitialSummaryChart() {
      let show_by = this.dateFilterDefaultValue;
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-order-summary?show_by=` +
            show_by +
            `&group_by=date`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        var tempData = [];
        for (const item in resp.results) {
          let data_chart = resp.results[item];
          let format_chart = {
            ColorFlag: 0,
            MaximumValue: 200,
            MinimumValue: data_chart.number_of_patients,
            Result: data_chart.number_of_patients,
            Test: "",
            TestDate: data_chart.date,
            medical_record_number: "",
            name: "",
          };
          tempData.push(format_chart);
        }

        this.dataChart = tempData;
      } catch (error) {
        console.log(error);
      }
    },
    async getInitialReferralChart() {
      let show_by = this.dateFilterDefaultValue;
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-order-summary?show_by=` +
            show_by +
            `&group_by=date`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        // this.dataChart = {}
        var tempData = [];
        for (const item in resp.results) {
          let data_chart = resp.results[item];
          let format_chart = {
            ColorFlag: 0,
            MaximumValue: 200,
            MinimumValue: data_chart.number_of_patients,
            Result: data_chart.referral_fee,
            Test: "",
            TestDate: data_chart.date,
            medical_record_number: "",
            name: "",
          };
          tempData.push(format_chart);
        }

        this.dataReferralChart = tempData;
      } catch (error) {
        console.log(error);
      }
    },
    updateSummaryChart(e) {
      this.dateFilterDefaultValue = e;
      this.getInitialSummaryChart();
    },
    async getProfile() {
      try {
        this.contactPrimary = this.dataProfile.company.contacts.filter(
          contactGet => {
            return true == contactGet.primary;
          }
        );

        (this.listMenu = [
          {
            id: 1,
            title: this.$t("tab.corporate_info"),
          },
          {
            id: 2,
            title: this.$t("tab.contact_info"),
          },
          {
            id: 3,
            title: this.$t("tab.account_info"),
          },
          // {
          //   id: 4,
          //   title: this.$t("tab.deposit_trans"),
          // },
        ]),
          (this.listContent = [
            {
              id: 1,
              title: this.$t("tab.summary"),
              data: [
                {
                  valueLeft:
                    this.dataProfile.company.info.company_name == null ||
                    this.dataProfile.company.info.company_name == "null"
                      ? "-"
                      : this.dataProfile.company.info.company_name,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.address == null ||
                    this.dataProfile.company.info.address == "null"
                      ? "-"
                      : this.dataProfile.company.info.address,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.city == null ||
                    this.dataProfile.company.info.city == "null"
                      ? "-"
                      : this.dataProfile.company.info.city +
                        ", " +
                        this.dataProfile.company.info.district,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.state == null ||
                    this.dataProfile.company.info.state == "null"
                      ? "-"
                      : this.dataProfile.company.info.state +
                        " " +
                        this.dataProfile.company.info.postal_code +
                        ", " +
                        this.dataProfile.company.info.country,
                },
                {
                  valueTitle: "HOME PHONE",
                  valueTitleLang: this.$t("field.phone"),
                  valueLeft:
                    this.contactPrimary != null &&
                    this.contactPrimary.length > 0
                      ? this.contactPrimary[0].phone_code +
                        this.contactPrimary[0].phone
                      : "-",
                },
                {
                  valueTitle: "EMAIL",
                  valueTitleLang: this.$t("field.email"),
                  valueLeft:
                    this.contactPrimary != null &&
                    this.contactPrimary.length > 0
                      ? this.contactPrimary[0].contact_email
                      : "-",
                },
              ],
              dataRight: [
                {
                  title: this.$t("field.account_number"),
                  value:
                    this.dataProfile.person.id == null ||
                    this.dataProfile.person.id == "null"
                      ? "-"
                      : this.dataProfile.person.id,
                },
                {
                  title: this.$t("field.sap_bp_id"),
                  value:
                    this.dataProfile.sap_code == null ||
                    this.dataProfile.sap_code == "null"
                      ? "-"
                      : this.dataProfile.sap_code,
                },
                {
                  title: this.$t("field.company_tax_no"),
                  value:
                    this.dataProfile.company.tax_info != null &&
                    this.dataProfile.company.tax_info.length > 0
                      ? this.dataProfile.company.tax_info[0].npwp_number
                      : "-",
                },
                {
                  title: this.$t("field.currency"),
                  value:
                    this.dataProfile.currency == null ||
                    this.dataProfile.currency == "null"
                      ? "-"
                      : this.dataProfile.currency,
                },
                // {
                //   title: this.$t("field.pic_name"),
                //   value:
                //   this.dataProfile.person.personal_info.name != null
                //       ?  this.dataProfile.person.personal_info.name
                //       : "-",
                // },
                // {
                //   title: this.$t("field.pic_mobile_phone"),
                //   value:
                //   this.dataProfile.person.personal_info.contacts.mobile_phone_number != null
                //       ?  this.dataProfile.person.personal_info.contacts.mobile_phone_number
                //       : "-",
                // },
              ],
              dataMobile: [
                {
                  valueLeft:
                    this.dataProfile.company.info.company_name == null ||
                    this.dataProfile.company.info.company_name == "null"
                      ? "-"
                      : this.dataProfile.company.info.company_name,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.address == null ||
                    this.dataProfile.company.info.address == "null"
                      ? "-"
                      : this.dataProfile.company.info.address,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.city == null ||
                    this.dataProfile.company.info.city == "null"
                      ? "-"
                      : this.dataProfile.company.info.city +
                        ", " +
                        this.dataProfile.company.info.district,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.state == null ||
                    this.dataProfile.company.info.state == "null"
                      ? "-"
                      : this.dataProfile.company.info.state +
                        " " +
                        this.dataProfile.company.info.postal_code +
                        ", " +
                        this.dataProfile.company.info.country,
                },
              ],
              dataMobileRight: [
                {
                  title: this.$t("field.phone"),
                  value:
                    this.dataProfile.company.contacts != null &&
                    this.dataProfile.company.contacts.length > 0
                      ? this.contactPrimary[0].phone_code +
                        this.dataProfile.company.contacts[0].phone
                      : "-",
                },
                {
                  title: this.$t("field.email"),
                  value:
                    this.dataProfile.company.contacts != null &&
                    this.dataProfile.company.contacts.length > 0
                      ? this.dataProfile.company.contacts[0].contact_email
                      : "-",
                },

                {
                  title: this.$t("field.account_number"),
                  value:
                    this.dataProfile.person.id == null ||
                    this.dataProfile.person.id == "null"
                      ? "-"
                      : this.dataProfile.person.id,
                },
                {
                  title: this.$t("field.sap_bp_id"),
                  value:
                    this.dataProfile.sap_code == null ||
                    this.dataProfile.sap_code == "null"
                      ? "-"
                      : this.dataProfile.sap_code,
                },
                {
                  title: this.$t("field.company_tax_no"),
                  value:
                    this.dataProfile.company.tax_info != null &&
                    this.dataProfile.company.tax_info.length > 0
                      ? this.dataProfile.company.tax_info[0].npwp_number
                      : "-",
                },
                {
                  title: this.$t("field.currency"),
                  value:
                    this.dataProfile.currency == null ||
                    this.dataProfile.currency == "null"
                      ? "-"
                      : this.dataProfile.currency,
                },
                // {
                //   title: this.$t("field.pic_name"),
                //   value:
                //   this.dataProfile.person.personal_info.name != null
                //       ?  this.dataProfile.person.personal_info.name
                //       : "-",
                // },
                // {
                //   title: this.$t("field.pic_mobile_phone"),
                //   value:
                //   this.dataProfile.person.personal_info.contacts.mobile_phone_number != null
                //       ?  this.dataProfile.person.personal_info.contacts.mobile_phone_number
                //       : "-",
                // },
              ],
            },
            {
              id: 2,
              title: this.$t("tab.contact_info"),
              data: [
                {
                  valueLeft:
                    this.dataProfile.company.info.company_name == null ||
                    this.dataProfile.company.info.company_name == "null"
                      ? "-"
                      : this.dataProfile.company.info.company_name,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.address == null ||
                    this.dataProfile.company.info.address == "null"
                      ? "-"
                      : this.dataProfile.company.info.address,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.city == null ||
                    this.dataProfile.company.info.city == "null"
                      ? "-"
                      : this.dataProfile.company.info.city +
                        ", " +
                        this.dataProfile.company.info.district,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.state == null ||
                    this.dataProfile.company.info.state == "null"
                      ? "-"
                      : this.dataProfile.company.info.state +
                        " " +
                        this.dataProfile.company.info.postal_code +
                        ", " +
                        this.dataProfile.company.info.country,
                },
                {
                  valueTitle: "HOME PHONE",
                  valueTitleLang: this.$t("field.phone"),
                  valueLeft:
                    this.contactPrimary != null &&
                    this.contactPrimary.length > 0
                      ? this.contactPrimary[0].phone_code +
                        this.contactPrimary[0].phone
                      : "-",
                },
                {
                  valueTitle: "EMAIL",
                  valueTitleLang: this.$t("field.email"),
                  valueLeft:
                    this.contactPrimary != null &&
                    this.contactPrimary.length > 0
                      ? this.contactPrimary[0].contact_email
                      : "-",
                },
              ],
              dataRight: [
                {
                  title: this.$t("field.account_number"),
                  value:
                    this.dataProfile.person.id == null ||
                    this.dataProfile.person.id == "null"
                      ? "-"
                      : this.dataProfile.person.id,
                },
                {
                  title: this.$t("field.sap_bp_id"),
                  value:
                    this.dataProfile.sap_code == null ||
                    this.dataProfile.sap_code == "null"
                      ? "-"
                      : this.dataProfile.sap_code,
                },
                {
                  title: this.$t("field.company_tax_no"),
                  value:
                    this.dataProfile.company.tax_info != null &&
                    this.dataProfile.company.tax_info.length > 0
                      ? this.dataProfile.company.tax_info[0].npwp_number
                      : "-",
                },
                {
                  title: this.$t("field.currency"),
                  value:
                    this.dataProfile.currency == null ||
                    this.dataProfile.currency == "null"
                      ? "-"
                      : this.dataProfile.currency,
                },
                // {
                //   title: this.$t("field.pic_name"),
                //   value:
                //   this.dataProfile.person.personal_info.name != null
                //       ?  this.dataProfile.person.personal_info.name
                //       : "-",
                // },
                // {
                //   title: this.$t("field.pic_mobile_phone"),
                //   value:
                //   this.dataProfile.person.personal_info.contacts.mobile_phone_number != null
                //       ?  this.dataProfile.person.personal_info.contacts.mobile_phone_number
                //       : "-",
                // },
              ],
              dataMobile: [
                {
                  valueLeft:
                    this.dataProfile.company.info.company_name == null ||
                    this.dataProfile.company.info.company_name == "null"
                      ? "-"
                      : this.dataProfile.company.info.company_name,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.address == null ||
                    this.dataProfile.company.info.address == "null"
                      ? "-"
                      : this.dataProfile.company.info.address,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.city == null ||
                    this.dataProfile.company.info.city == "null"
                      ? "-"
                      : this.dataProfile.company.info.city +
                        ", " +
                        this.dataProfile.company.info.district,
                },
                {
                  valueLeft:
                    this.dataProfile.company.info.state == null ||
                    this.dataProfile.company.info.state == "null"
                      ? "-"
                      : this.dataProfile.company.info.state +
                        " " +
                        this.dataProfile.company.info.postal_code +
                        ", " +
                        this.dataProfile.company.info.country,
                },
              ],
              dataMobileRight: [
                {
                  title: this.$t("field.phone"),
                  value:
                    this.dataProfile.company.contacts != null &&
                    this.dataProfile.company.contacts.length > 0
                      ? this.contactPrimary[0].phone_code +
                        this.dataProfile.company.contacts[0].phone
                      : "-",
                },
                {
                  title: this.$t("field.email"),
                  value:
                    this.dataProfile.company.contacts != null &&
                    this.dataProfile.company.contacts.length > 0
                      ? this.dataProfile.company.contacts[0].contact_email
                      : "-",
                },

                {
                  title: this.$t("field.account_number"),
                  value:
                    this.dataProfile.person.id == null ||
                    this.dataProfile.person.id == "null"
                      ? "-"
                      : this.dataProfile.person.id,
                },
                {
                  title: this.$t("field.sap_bp_id"),
                  value:
                    this.dataProfile.sap_code == null ||
                    this.dataProfile.sap_code == "null"
                      ? "-"
                      : this.dataProfile.sap_code,
                },
                {
                  title: this.$t("field.company_tax_no"),
                  value:
                    this.dataProfile.company.tax_info != null &&
                    this.dataProfile.company.tax_info.length > 0
                      ? this.dataProfile.company.tax_info[0].npwp_number
                      : "-",
                },
                {
                  title: this.$t("field.currency"),
                  value:
                    this.dataProfile.currency == null ||
                    this.dataProfile.currency == "null"
                      ? "-"
                      : this.dataProfile.currency,
                },
                // {
                //   title: this.$t("field.pic_name"),
                //   value:
                //   this.dataProfile.person.personal_info.name != null
                //       ?  this.dataProfile.person.personal_info.name
                //       : "-",
                // },
                // {
                //   title: this.$t("field.pic_mobile_phone"),
                //   value:
                //   this.dataProfile.person.personal_info.contacts.mobile_phone_number != null
                //       ?  this.dataProfile.person.personal_info.contacts.mobile_phone_number
                //       : "-",
                // },
              ],
            },

            {
              id: 3,
              title: this.$t("page.profile.tax_info"),
              data: [
                {
                  title: this.$t("page.profile.npwp_number"),
                  value:
                    this.dataProfile.company.tax_info != null &&
                    this.dataProfile.company.tax_info.length > 0
                      ? this.dataProfile.company.tax_info[0].npwp_number
                      : "-",
                },
                {
                  title: this.$t("page.profile.npwp_registration_date"),
                  value: "-",
                },
                {
                  title: this.$t("page.profile.npwp_address"),
                  value:
                    this.dataProfile.company.tax_info != null &&
                    this.dataProfile.company.tax_info.length > 0
                      ? this.dataProfile.company.tax_info[0].npwp_address
                      : "-",
                },
              ],
            },
            {
              id: 4,
              title: this.$t("page.profile.bank_info"),
              data: [
                {
                  title: this.$t("page.profile.bank_name"),
                  value:
                    this.dataProfile.company.bank_info != null &&
                    this.dataProfile.company.bank_info.length > 0
                      ? this.dataProfile.company.bank_info[0].bank_name
                      : "-",
                },
                {
                  title: this.$t("page.profile.bank_branch"),
                  value:
                    this.dataProfile.company.bank_info != null &&
                    this.dataProfile.company.bank_info.length > 0
                      ? this.dataProfile.company.bank_info[0].bank_branch
                      : "-",
                },
                {
                  title: this.$t("page.profile.bank_account_number"),
                  value:
                    this.dataProfile.company.bank_info != null &&
                    this.dataProfile.company.bank_info.length > 0
                      ? this.dataProfile.company.bank_info[0]
                          .bank_account_number
                      : "-",
                },
                {
                  title: this.$t("page.profile.bank_account_name"),
                  value:
                    this.dataProfile.company.bank_info != null &&
                    this.dataProfile.company.bank_info.length > 0
                      ? this.dataProfile.company.bank_info[0].bank_account_name
                      : "-",
                },
              ],
            },
            {
              id: 5,
              title: this.$t("page.profile.service_rate"),
              data: [
                {
                  title: this.$t("page.profile.discount_rate"),
                  value:
                    this.dataProfile.company.service_rate != null &&
                    this.dataProfile.company.service_rate.length > 0
                      ? this.dataProfile.company.service_rate[0].discount_rate
                      : "-",
                },
                {
                  title: this.$t("page.profile.contract_start_date"),
                  value:
                    this.dataProfile.company.service_rate != null &&
                    this.dataProfile.company.service_rate.length > 0
                      ? this.dataProfile.company.service_rate[0]
                          .contract_start_date
                      : "-",
                },
                {
                  title: this.$t("page.profile.contract_end_date"),
                  value:
                    this.dataProfile.company.service_rate != null &&
                    this.dataProfile.company.service_rate.length > 0
                      ? this.dataProfile.company.service_rate[0]
                          .contract_end_date
                      : "-",
                },
              ],
            },
          ]);
        this.dataTaxInfo = this.dataProfile.company.tax_info;
      } catch (error) {
        console.log(error);
      }
      this.activeTab = this.listContent[0].id;
    },
    getDateThisWeek() {
      const date = moment();
      const startThisWeek = moment(date).startOf("isoWeek");
      const endThisWeek = moment(date).endOf("isoWeek");
      this.thisWeek = this.getListDateRange(startThisWeek, endThisWeek);
    },
    getListDateRange(startDate, endDate) {
      var start = new Date(startDate);
      var end = new Date(endDate);
      var dates = [];

      while (start <= end) {
        dates.push(new Date(start));
        start.setDate(start.getDate() + 1);
      }
      return dates;
    },
    sumField(key) {
      // sum data in give key (property)
      return this.datasX.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumReferral(data) {
      // sum data in give key (property)
      let sum = 0;
      for (let i = 0; i < data.length; i++) {
        // if (data[i].status_name == "active") {
        sum = sum + parseInt(data[i].amount);
        // }
      }
      return sum;
    },
    sumReferralMonth(key) {
      // sum data in give key (property)
      return this.orderMonthData.reduce((a, b) => a + (b[key] || 0), 0);
    },
    updateReferralChart(e) {
      this.dateFilterDefaultValue = e;
      this.getInitialReferralChart();
    },
  },
};
</script>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-other .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-other .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-other .v-data-table__wrapper {
  /* overflow-x: auto; */
  overflow-y: auto;
  height: 500px !important;
}
.table-stick-other thead {
  /* position: sticky; */
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-stick-homedoctor thead {
  /* position: sticky; */
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-stick-homedoctor thead tr th {
  white-space: nowrap;
}
.table-stick-homedoctor tbody tr td {
  white-space: nowrap;
}

.table-account-balance-company thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-account-balance-company thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-account-balance-company tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-account-balance-company tbody tr:hover {
  background-color: transparent !important;
}
.corp-m {
  margin-top: 4px !important;
}
@media screen and (max-width: 767px) {
  .corp-m {
    margin-top: -10px !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
.center-empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-switch {
  color: $main_2;
  margin-right: 8px;
}

.h4 {
  color: $main_2;
}
.list-data {
  border-bottom: 1px solid $gray_2;
  // padding: 4px 8px 0 8px;
  padding: 12px 8px 0 8px;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.card-chart {
  position: relative;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}

.v-tabs {
  width: 720px;
}

.center-chart {
  background: transparent;
  height: 80%;
  width: 80%;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 72px;
    margin-bottom: 0;
  }
}
.label-doughnut {
  align-self: center;
}
.doughnut-title {
  padding: 4px;
}
.chart-title {
  padding: 4px 12px;
}

.v-input__control {
  height: 30px;
}
.v-input__slot {
  height: 30px;
}

.v-select__selections {
  width: 30px;
  height: 30px;
}

.v-select {
  height: 30px;
  background: #ffb946;
  color: #000;
}

.sticky-table-footer {
  td {
    font-weight: bold;
  }
}

.card-chart .v-data-table {
  td {
    font-size: 12px;
  }
}

.table-patient {
  th {
    .text-gray {
      color: $main_2;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  td {
    .text-gray {
      color: $gray_table;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .text-black {
      color: $black_table;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.card-collapse {
  padding: 0px 0px 12px 0px;
}
.card-collapse-mobile {
  padding: 0px;
}
.menu__icon {
  margin-right: 14px;
  display: flex;
  align-items: center;
  width: 20px;
}
.card-booking {
  padding: 10px 15px;
  border-radius: 10px;
  background: #ffffff;
  height: 100%;
  border: 1px solid #777777;
  box-sizing: border-box;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
}
.booking-title {
  color: #1f3c87;
  font-weight: 700;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.product-name {
  color: #1f3c87;
  font-weight: 600;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 13px;
}
.booking-detail {
  color: #1f3c87;
  font-size: 13px;
}
// .booking-city {
//   @media screen and (min-width: 960px) {
//     padding-top: 0px;
//   }
// }
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}
.text-new-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
a {
  text-decoration: none;
}
</style>
